import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export default function Prix() {
  return (
    <div className="p-5 mx-auto sm:p-10 md:p-16 bg-coolGray-100 text-gray-800">
      <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12 text-center">
        <div class="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
          <h2 class="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto text-center">
            <span class="relative inline-block text-blue-900">Frais de scolarité 2022-2023</span>
          </h2>

          <div class="flex mt-6 justify-center">
            <div class="w-16 h-1 rounded-full bg-blue-900 inline-flex"></div>
          </div>
        </div>
      </div>
      <div className="flex flex-col max-w-6xl mx-auto overflow-hidden rounded">
        <StaticImage
          src="../images/tarifs.webp"
          alt=""
          className="w-full h-60 sm:h-96 bg-coolGray-500"
        />

        <div className="p-6 pb-12 m-4 mx-auto -mt-16 space-y-6  sm:px-10 sm:mx-12 lg:rounded-md bg-white shadow-lg z-10">
          <div class=" w-full mx-auto overflow-auto">
            <table class="table-auto w-full text-left whitespace-no-wrap">
              <thead>
                <tr>
                  <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-lg md:text-xl bg-gray-100 rounded-tl rounded-bl">
                    Plan
                  </th>
                  <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-lg md:text-xl bg-gray-100">
                    Montant
                  </th>
                  <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-lg md:text-xl bg-gray-100">
                    Note
                  </th>

                  <th class="w-10 title-font tracking-wider font-medium text-gray-900 text-lg md:text-xl bg-gray-100 rounded-tr rounded-br"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="w-auto md:text-lg text-base px-4 py-3">
                    FRAIS DE PREMIÈRE INSCRIPTION
                  </td>
                  <td class="w-auto md:text-lg text-base px-4 py-3">1500 TND</td>
                  <td class="w-auto md:text-lg text-base px-4 py-3">
                    Valable pour toute la scolarité de l'enfant
                  </td>
                </tr>
                <tr>
                  <td class="w-auto md:text-lg text-base px-4 py-3">FRAIS DE RÉINSCRIPTION</td>
                  <td class="w-auto md:text-lg text-base px-4 py-3">500 TND</td>
                </tr>
                <tr>
                  <td class="border-t-2 border-gray-200 px-4 py-3 md:text-lg text-base">
                    FRAIS DE SCOLARITÉ ANNUEL
                  </td>
                  <td class="border-t-2 border-gray-200 px-4 py-3 md:text-lg text-base">
                    7500 TND
                  </td>
                  <td class="border-t-2 border-gray-200 px-4 py-3 md:text-lg text-base">
                    Le paiement se fait au début de chaque trimestre (2500 TND / trismestre)
                  </td>
                </tr>
                <tr>
                  <td class="border-t-2 border-gray-200 px-4 py-3 md:text-lg text-base">
                    FRAIS ANNUELS DE CANTINE
                    <br />
                    Optionnel
                  </td>
                  <td class="border-t-2 border-gray-200 px-4 py-3 md:text-lg text-base">
                    1400 TND
                  </td>
                  <td class="border-t-2 border-gray-200 px-4 py-3 md:text-lg text-base">
                    Par année
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="mt-4">
              <p className="italic mb-4">
                *Le respect du calendrier et échéances de paiement nous permettra de poursuivre
                toutes nos missions de manière optimale{' '}
              </p>
              <br />
              <h2 className="text-blue-900 font-bold md:tex-xl text-lg ">
                Reduction pour fratrie :
              </h2>
              <br />
              <p className="md:tex-xl text-lg">
                En cas de fratries, une réduction de 10% est consentie sur les frais de scolarité
                pour un deuxième enfant et 15% est consentie pour chaque enfant à partir du
                troisième.
              </p>
              <br />
              <h2 className="text-blue-900 font-bold md:tex-xl text-lg">Modalités de paiement :</h2>
              <br />
              <p className="md:tex-xl text-lg">
                Le paiement peut se faire par versement en espèces, remise chèque ou virement bancaire
                sur un de nos comptes.
              </p>
              <br />
              <p className="md:tex-xl text-lg">
                Tous ces frais ne seront ni remboursables ni transférables en cas de désistement.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
