import React from 'react';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import AnimationRevealPage from '../../components/AnimationRevealPage';
import Prix from '../../components/Prix';

export default function Tarifs() {
  return (
    <AnimationRevealPage>
      <Layout>
        <Seo
          title="Collège Jules Verne Kairouan - Nos tarifs"
          description="N'hésitez pas à nous contacter pour toutes questions ou demande"
        />
        <Prix />
      </Layout>
    </AnimationRevealPage>
  );
}
